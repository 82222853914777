import React, {useState} from "react";
import { graphql } from 'gatsby'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate } from 'gatsby';

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import EmailCard from "../components/EmailCard"
import TemplatePreview from "../components/TemplatePreview"
import SubTemplatePageLayout from "../components/SubTemplatePageLayout"


import {
  Link,
  Grid,
} from '@mui/material';

import * as helpers from "../helpers"
import CONSTS from "../consts"

const PlainTextReviewRequestEmailPage = (props) => {
  const [previewingTemplateIndex, setPreviewingTemplateIndex] = useState(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const keyCheck = React.useCallback((event) => {
    const keyCode = event.keyCode; 

    if (previewDialogOpen) {
      if (keyCode === 37) {
        onPreviewLastClicked();
      } else if (keyCode === 39) {
        onPreviewNextClicked();
      }
    }
  },[previewDialogOpen, previewingTemplateIndex]);

  React.useEffect(() => {
    window.addEventListener('keyup', keyCheck);

    return () => window.removeEventListener('keyup', keyCheck);
  }, [keyCheck])

  const templates = helpers.normalizeTemplatesData(props.data.emailTemplatesPlainText.nodes);

  const nextDisabled = previewingTemplateIndex === templates.length - 1;
  const lastDisabled = previewingTemplateIndex === 0;

  const onPreviewLastClicked = () => {
    if (!lastDisabled) {
      setPreviewingTemplateIndex(previewingTemplateIndex - 1);
    }
  };

  const onPreviewNextClicked = () => {
    if (!nextDisabled) {
      setPreviewingTemplateIndex(previewingTemplateIndex + 1)
    }
  };

  return (
    <Layout>
      <SubTemplatePageLayout
        header='Plain Text Email Templates'
        headerUrl='text_email_header.jpg'
        subHead='Simple and effective templates that are easy for anyone to send.'
        textContent={<>
          Welcome to our collection of plain text review request emails!
          <br/><br/>
          Sometimes, simple is best, and that's exactly what you'll find here.
          <br/><br/>
          Think "regular" or "text-only" when you hear plain text email. We just need to be specific to differentiate them from <Link href='/html-review-request-emails' title='HTML email collection page'>HTML emails</Link>.
          <br/><br/><br/><br/>
          To start, browse our templates.
          <br/><br/>
          When you find one you are interested in, click on it. Doing so will open a larger preview that provides more information, including how to use it.
        </>}
        moreTextContent={<>
          Each of our emails is straightforward and to the point, with a clear message that encourages customers to leave a review. With a direct link to the review page and clear instructions, leaving a review is easy and convenient.
          <br/><br/>
          Every template on the site is free to use and available for download without an account.
          <br/><br/>
          It is as easy as choosing one of our text-only templates, copying it into your email, substituting the placeholder values and clicking send.
          <br/><br/>
          With our plain text review request emails, you'll be able to gather valuable feedback and improve your business in no time!
        </>}
        templatesLength={templates.length}
      >
        
        <Grid container spacing={4}>
          {templates.map((template, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <EmailCard
                template={template}
                selected={previewingTemplateIndex === index}
                templateLink={CONSTS.PATHS.INDIVIDUAL_PLANE_TEXT_EMAIL_TEMPLATE.replace('{name}', template.id)}
                onPreviewTemplate={() => {
                  if (smallScreen) {
                    navigate(CONSTS.PATHS.INDIVIDUAL_PLANE_TEXT_EMAIL_TEMPLATE.replace('{name}', template.id))
                  } else {
                    setPreviewingTemplateIndex(index);
                    setPreviewDialogOpen(true);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
        <TemplatePreview
          individualPagePath={CONSTS.PATHS.INDIVIDUAL_PLANE_TEXT_EMAIL_TEMPLATE}
          template={previewingTemplateIndex !== null ? templates[previewingTemplateIndex] : null}
          open={previewDialogOpen}
          onClose={() => {
            setPreviewDialogOpen(false);
          }}
          nextDisabled={nextDisabled}
          lastDisabled={lastDisabled}
          onLastClicked={() => onPreviewLastClicked()}
          onNextClicked={() => onPreviewNextClicked()}
          visualType='PLAIN_TEXT_RENDERED'
        />
      </SubTemplatePageLayout>
    </Layout>
  );
}

export const Head = ({location, data}) => <Seo
  title={`Ready to send text-only review invitation emails | Over ${(data.emailTemplatesPlainText.nodes.length - 1)} to choose from!`}
  description="Looking for simple and effective ways to request feedback from your customers? Explore our collection of review request email templates and start collecting valuable insights today! Free + No Account Required."
  url={location.pathname ? location.pathname : null}
/>

export const query = graphql`
  query allTemplateQuery {
    emailTemplatesPlainText: allDirectory(sort: {order: ASC, fields: relativePath}, filter: {sourceInstanceName: {eq: "emailTemplatesPlainText"}, relativeDirectory: {eq: ""}}) {
      nodes {
        relativePath
        childFiles {
          name
          childTemplateData {
            description
            variables {
              description
              variable
              example
            }
          }
          childPlainText {
            content
          }
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;



export default PlainTextReviewRequestEmailPage
